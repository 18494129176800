import React from "react";
import "animate.css";
import Footer from "../Footer";

function Projects() {
  return (
    <section className="max-w-7xl flex flex-col gap-8 mt-20 animate__animated animate__fadeInDown">
      <div className="max-w-7xl section-header text-center location-id">
        Projects/Research
      </div>
      <div className="bg-yellow-700/50 card">
        <p className="title-header text-center">
          Intercollegiate Brazilian Jiujitsu Tournament 2023
        </p>
        <p>
          This past spring was Brazilian Jiujitsu Club at UC Irvine's first
          Anteater Invitational. My board and I had the opportunity to work with
          USA Grappling to sanction this event and got everything to run
          smoothly. All the competitors from the 6 different schools showed
          their hard work during the competition. These school orgs and clubs
          included jiujitsu clubs at UCLA, CalPoly Pomona, CSU Fullerton, CSU
          Northridge, UCSD, and of course, UCIrvine.
        </p>
        <div className="flex flex-row gap-2 w-full flex-wrap">
          <p className="bg-green-400/40 border-black px-2 py-1 rounded-full items-center justify-center w-fit h-fit">
            Project Management
          </p>
          <p className="bg-red-400/40 border-black px-2 py-1 rounded-full items-center justify-center w-fit h-fit">
            Mentorship
          </p>
          <p className="bg-blue-400/40 border-black px-2 py-1 rounded-full items-center justify-center w-fit h-fit">
            Communication
          </p>
          <p className="bg-orange-400/40 border-black px-2 py-1 rounded-full items-center justify-center w-fit h-fit">
            Leadership
          </p>
        </div>
      </div>
      <div className="bg-yellow-700/50 card">
        <p className="title-header text-center">
          Bioresearch @ Stanford University
        </p>
        <p>
          During my research internship, I was tasked with reading and
          performing some of my own research on my choice of animals. I read
          plenty of research papers about the spatial cognition and navigation
          in sea turtles and bees; I feel like I could talk about them for days.
          At the closing of my internship, I created a short stop motion video
          showcasing my findings which included: how bees communicated distance,
          how bats navigated the sky, and how turtles use magnetoreception to
          navigate the world's oceans.
        </p>
        <div className="flex flex-row gap-2 w-full flex-wrap">
          <p className="bg-green-400/40 border-black px-2 py-1 rounded-full items-center justify-center w-fit h-fit">
            Research
          </p>
          <p className="bg-red-400/40 border-black px-2 py-1 rounded-full items-center justify-center w-fit h-fit">
            Mentorship
          </p>
          <p className="bg-blue-400/40 border-black px-2 py-1 rounded-full items-center justify-center w-fit h-fit">
            Communication
          </p>
          <p className="bg-orange-400/40 border-black px-2 py-1 rounded-full items-center justify-center w-fit h-fit">
            Research Methods
          </p>
        </div>
      </div>
      <div className="bg-yellow-700/50 card">
        <h2 className="title-header text-center">Figma Designs</h2>
        <p>
          These are just some personal projects that I have designed for myself
          and my interests! UI/UX and the design process are skills I have been
          pracitcing and working on during my own time. I find that user-centric
          designs and projects provide products that users in a beneficial yet
          subtle way.
        </p>
        <a
          className="text-xl p-3 italic underline"
          target="blank"
          href="https://www.figma.com/file/oki7ADnWI6g0MvXer1e55z/PlantParenthood?node-id=0%3A1&t=kM2bsLllXhToPyeH-1"
        >
          PlantParenthood
        </a>
        <div className="flex flex-row gap-2 w-full flex-wrap">
          <p className="bg-green-400/40 border-black px-2 py-1 rounded-full items-center justify-center w-fit h-fit">
            Research
          </p>
          <p className="bg-orange-400/40 border-black px-2 py-1 rounded-full items-center justify-center w-fit h-fit">
            Research Methods
          </p>{" "}
          <p className="bg-red-400/40 border-black px-2 py-1 rounded-full items-center justify-center w-fit h-fit">
            Figma
          </p>{" "}
          <p className="bg-blue-400/40 border-black px-2 py-1 rounded-full items-center justify-center w-fit h-fit">
            Design
          </p>
          <p className="bg-purple-400/40 border-black px-2 py-1 rounded-full items-center justify-center w-fit h-fit">
            Wireframes
          </p>
        </div>
      </div>
      <div className="bg-yellow-700/50 card">
        <h2 className="title-header text-center">
          App Redesign: ZotFinder App - Role: UI/UX Resarch and Design
        </h2>
        <p>
          Currently a work in progress! The main idea of ZotFinder is to help
          students navigate through the UCI campus. However, the problem that I
          saw with the app is the lack of attention to detail and lack of
          convenience; thus, I gathered a team within Design at UCI to tackle
          this problem. Right now, we are using surveys and interviews as our
          research and will soon design a prototype accordingly.
        </p>
        <div className="flex flex-row gap-2 w-full flex-wrap">
          <p className="bg-green-400/40 border-black px-2 py-1 rounded-full items-center justify-center w-fit h-fit">
            Research
          </p>
          <p className="bg-orange-400/40 border-black px-2 py-1 rounded-full items-center justify-center w-fit h-fit">
            Research Methods
          </p>
        </div>
      </div>
      <div className="bg-yellow-700/50 card">
        <h2 className="title-header text-center">This Website!</h2>
        <p>
          I wrote this website for the purpose of showcasing and practicing my
          skills in HTML, CSS/TailwindCSS, and React.JS. I started my coding
          journey by self teaching myself in June of 2022. This website is
          always under improvements to improve responsiveness and user
          interface! More content will be added over time; you can follow me and
          my jiujitsu and surf journey in my upcoming Blog posts!
        </p>
        <div className="flex flex-row gap-2 w-full flex-wrap">
          <p className="bg-green-400/40 border-black px-2 py-1 rounded-full items-center justify-center w-fit h-fit">
            Web Design
          </p>
          <p className="bg-red-400/40 border-black px-2 py-1 rounded-full items-center justify-center w-fit h-fit">
            React.JS
          </p>
          <p className="bg-blue-400/40 border-black px-2 py-1 rounded-full items-center justify-center w-fit h-fit">
            HTML
          </p>
          <p className="bg-orange-400/40 border-black px-2 py-1 rounded-full items-center justify-center w-fit h-fit">
            CSS
          </p>
          <p className="bg-purple-400/40 border-black px-2 py-1 rounded-full items-center justify-center w-fit h-fit">
            TailwindCSS
          </p>
        </div>
      </div>
      <Footer></Footer>
    </section>
  );
}

export default Projects;
