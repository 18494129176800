import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <section>
      <nav className="py-4 border-t-2 text-2xl text-center mt-5">
        <a href="#">Contact me!</a>

        <p>kobephoward@gmail.com</p>
      </nav>
    </section>
  );
}

export default Footer;
