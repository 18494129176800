import { SocialIcon } from "react-social-icons";
import "./App.css";
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import Home from "./routes/Home";
import Projects from "./routes/Projects";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/projects",
    element: <Projects />,
  },
]);

//npm

function App() {
  return (
    <div
      style={{ width: "100vw", height: "100vh" }}
      className="w-full h-full bg-white animate__animated animate__fadeInDown"
    >
      <div className="mx-auto max-w-7xl px-2">
        <nav className="flex justify-between items-center py-4 border-b text-2xl">
          <a href="/">ksph</a>
          <div className="flex gap-4 items-center">
            {/* <a href="#">Projects</a> */}
            <a
              className=""
              target="blank"
              href="https://www.linkedin.com/in/kobe-pham-howard-955397197/"
            >
              Linkedin
            </a>
            <a
              target="blank"
              href="https://drive.google.com/file/d/1HF49HrbyG-Ef7bOStN327TulG7b0WRl8/view?usp=drive_link"
            >
              Resume
            </a>
            <a href="/">Home</a>
            <a href="/projects">Projects</a>
          </div>
        </nav>
        <RouterProvider router={router} />
      </div>
    </div>
  );
}

export default App;
