import React from "react";
import kobeImage from "../images/Kobe_profile.jpg";
import "animate.css";
import Footer from "../Footer";

function Home() {
  return (
    <div>
      {/* ANIMATIONS HERE: https://animate.style/ */}
      <div className="mt-12 flex flex-col gap-8 ">
        <section className="grid grid-rows-1 md:grid-cols-[1fr_auto] grid-cols-1 gap-4 ">
          <img
            src={kobeImage}
            alt="my portrait"
            className="md:col-start-2 md:row-start-1 shadow-lg lg:w-[30rem] md:w-[20rem] w-full rounded-lg"
          />
          <div className="md:col-start-1 flex gap-4 flex-col">
            <div>
              <div className="pt-2 pb-1 pl-2 border-x border-black rounded-lg">
                <p className="font-bold text-3xl">Kobe Spencer Pham-Howard</p>
                <p>"A cupped hand holds more water than a closed fist"</p>
              </div>
            </div>
            <div className="text-lg">
              <p>Recent UC Irvine graduate looking to learn something new.</p>
            </div>
            <section className="md:col-start-1 flex gap-3 flex-col mt-8">
              <h3 className="title-header">University of California, Irvine</h3>
              <p className="text-lg">
                <i>Irvine, California - </i>Bachelors of Arts, Psychological
                Sciences, June 2023
              </p>
              <h3 className="title-header">Brazilian Jiujitsu @ UC Irvine</h3>
              <p className="text-lg">
                <i>Irvine, California - </i>Club President, June 2022 to June
                2023
              </p>
              <h3 className="title-header">Coding Minds</h3>
              <p className="text-lg">
                <i>Irvine, California - </i>Coding Instructor, July 2022 to June
                2023
              </p>
              <h3 className="title-header">Stanford University</h3>
              <p className="text-lg">
                <i>Stanford, California - </i> Research Intern, January 2021 to
                April 2021
              </p>
            </section>
          </div>
        </section>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default Home;
